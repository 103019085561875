const formatPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digit characters except plus sign
  const cleaned = ('' + phoneNumber).replace(/[^\d+]/g, '');

  // Check for international format with country code
  const intlMatch = cleaned.match(/^\+?(\d{1,3})?(\d{3})(\d{3})(\d{4})$/);
  if (intlMatch) {
    const [, countryCode, area, prefix, line] = intlMatch;
    return countryCode 
      ? `+${countryCode} (${area}) ${prefix}-${line}`
      : `(${area}) ${prefix}-${line}`;
  }

  return phoneNumber; // Return original if format doesn't match
};

export { formatPhoneNumber };
